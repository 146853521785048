@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;900&display=swap');
*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  margin-right: 4px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /* background-color: hsla(0, 0%, 100%, 0.4); */
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 1px rgb(0 0 0 / 40%);
}

input[type='range'] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  margin-top: -5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border: 1px solid #000000;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  height: 16px;
  width: 16px;
  border: 1px solid #000000;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  background: #7a38ff;
  border-radius: 10px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #7a38ff;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  background: #7a38ff;
  border-radius: 10px;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #7a38ff;
  border-radius: 2.6px;
}
input[type='range']:focus::-ms-fill-lower {
  background: #7a38ff;
}
input[type='range']::-ms-fill-upper {
  background: #7a38ff;
  border-radius: 2.6px;
}
input[type='range']:focus::-ms-fill-upper {
  background: #7a38ff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* ios */
textarea,
input[type='text'],
input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='search'],
input[type='url'] {
  -webkit-appearance: none;
}

.loader {
  border-top-color: #7a38ff;
  border-right-color: #7a38ff;
  border-bottom-color: #7a38ff;
  border-left-color: transparent;
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gr-xeuibg-footer-container {
  position: relative;
}
.gr-xdfgbg-footer {
  /* margin-bottom: 20px !important;*/
  width: 100% !important;
  text-align: center !important;
  font-size: 12px;
}
.gr-xdfgbg-footer .gr-dsf-badge-container a {
  background: white !important;
  border-radius: 4px;
  width: 150px;
  padding: 8px 10px;
  transform-origin: 50% 50%;
  z-index: 999;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%), 0 6px 13px rgb(0 0 0 / 20%);
  text-decoration: none !important;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  color: #0e0e2c;
  font-weight: 600;
  letter-spacing: 0.2px;
  font-family: Helvetica, sans-serif;
}
.gr-xdfgbg-footer .gr-dsf-badge-container a:hover {
  box-shadow: 0 1px 3px rgb(0 0 0 / 50%), 0 6px 13px rgb(0 0 0 / 40%);
}
.gr-xdfgbg-footer .gr-dsf-badge-container a img {
  max-width: 64px;
  margin: -2px 0px 0px 2px;
}
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #F8F8F8;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 3s infinite;
  content: '';
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder { /* Recent browsers */
  text-transform: none;
}
